<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="isActive" @click="archiveContracts">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-down</v-icon>
          {{ $t('button.archive') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list-item v-if="media.isMobile" @click="selectAll">
      <v-list-item-title>
        <v-icon right>mdi-checkbox-marked</v-icon>
        {{ $t('button.select_all') }}
      </v-list-item-title>
    </v-list-item>
  </v-menu>
</template>

<script>
// Constants
import * as entityStatuses from '@/constants/entityStatuses';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'ContractsMenu',

  components: { IconButton },

  inject: ['media'],

  props: {
    status: { type: String, required: true },
    page: { type: Number, default: 1 },
  },

  computed: {
    isActive() {
      return this.status === entityStatuses.CONTRACTS;
    },
  },

  methods: {
    archiveContracts() {
      this.$emit('archive-contracts');
    },

    selectAll() {
      this.$emit('select-contracts');
    },
  },
};
</script>
